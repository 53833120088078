<template>
	<div class="exhibitor-details-photo">
		<div class="exhibitor-details-photo__container">
			<div class="exhibitor-details-photo__container-middle">
				<iframe
					v-if="exhibitor.exhivideo && embedVideo(exhibitor.exhivideo)"
					:src="embedVideo(exhibitor.exhivideo)"
					width="640"
					height="360"
					frameborder="0"
					allow="fullscreen"
					style="border-radius: 8px"
				></iframe>
				<img v-else-if="exhibitorPhoto" class="exhibitor-details-photo__container-middle-image" :src="exhibitorPhoto" alt="foto" />
				<img v-else class="exhibitor-details-photo__container-middle-image" :src="defaultPhoto" />
			</div>
			<div class="exhibitor-details-photo__container-bottom">
				<div class="exhibitor-details-photo__container-bottom-text">{{ exhibitor.exhiobser }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';

export default {
	name: 'ExhibitorDetailsPhoto',

	props: {
		exhibitorLogo: {
			type: String,
			default: null
		},
		defaultPhoto: {
			type: String,
			default: null
		},
		exhibitor: {}
	},

	data() {
		return {
			exhibitorPhoto: null
		};
	},

	mounted() {
		this.getExhibitorPhoto();
	},

	methods: {
		getExhibitorPhoto: async function () {
			const data = await getDocument('exhibitor', this.$route.params.id, 'exhibitor_primary');
			if (data[0]) this.exhibitorPhoto = data[0].url;
		},

		embedVideo: function (url) {
			const urlSplitted = url.split('/');
			const host = urlSplitted[2];
			if (host === 'www.youtube.com') {
				return 'https://www.youtube.com/embed/' + this.getYoutubeId(url);
			} else if (host === 'vimeo.com') {
				return 'https://player.vimeo.com/video/' + urlSplitted[urlSplitted.length - 1];
			} else return null;
		},

		getYoutubeId: function (url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			const match = url.match(regExp);

			return match && match[2].length === 11 ? match[2] : null;
		}
	}
};
</script>


<style lang="scss" scoped>
.exhibitor-details-photo__container {
	&-middle {
		padding-bottom: 1.875rem;
		display: flex;
		justify-content: center;

		&-image {
			border-radius: 8px;
			width: 100%;
			aspect-ratio: 16/9;
			object-fit: cover;
		}
	}

	&-bottom {
		padding-bottom: 1.875rem;

		&-text {
			font-weight: normal;
			font-size: 20px;
			color: #575e60;
			white-space: pre-wrap;
		}
	}
}
</style>
