<template>
	<div>
		<div>
			<div class="exhibitor-details-products__top">
				<div class="exhibitor-details-products__top-title">
					<img :src="IconCompanies" alt="icon" />
					<h3 class="exhibitor-details-products__top-title-text">{{ $t('exhibitors.companies') }}</h3>
				</div>
				<div class="exhibitor-details-products__top-pagination">
					<button @click="goPrev()" class="exhibitor-details-products__top-pagination-button">
						<i :class="current === 1 ? 'fal fa-chevron-left' : 'fas fa-chevron-left'"></i>
					</button>
					<button @click="goNext()" class="exhibitor-details-products__top-pagination-button">
						<i :class="current === Math.ceil(exhibitors.length / pageSize) ? 'fal fa-chevron-right' : 'fas fa-chevron-right'"></i>
					</button>
				</div>
			</div>
			<template style="width: 100%">
				<v-row class="row" no-gutters>
					<v-col v-for="(item, i) in paginated" :key="item.exhibitor" md="3">
						<v-card class="mx-auto my-12 card" :outlined="true">
							<div style="position: relative">
								<div class="exhibitor-details-products__item-top-image" style="position: absolute">
									<router-link :to="{ name: 'ExhibitorDetails', params: { id: item.exhibitor } }">
										<img
											class="exhibitor-details-products__item-top-image-img"
											v-if="item.imageurl"
											:src="item.imageurl"
											alt="photo"
										/>
										<img class="exhibitor-details-products__item-top-image-img" v-else :src="defaultPhoto" alt="photo" />
									</router-link>
								</div>
								<div style="position: absolute; margin: 0.625rem 0 0 0.625rem">
									<span
										v-if="item.typesname"
										class="exhibitor-details-products__item-top-image-PREMIUM"
										:style="item.pathcolor ? `background-color: ${item.pathcolor}` : 'background-color: #d4af37;'"
									>
										{{ item.typesname.substring(1) }}
									</span>
								</div>
								<div style="position: absolute; right: 0; margin-right: 0.625rem">
									<div class="exhibitor-details-products__item-top-image-buttons">
										<ShareDropdown :url="getShareURL(item.exhibitor)" :exhibitorName="item.name" white />
										<Modal :exhibitors="item" card />
									</div>
								</div>
							</div>
							<div class="bottom">
								<div class="text">
									<router-link :to="{ name: 'ExhibitorDetails', params: { id: item.exhibitor } }" style="text-decoration: none">
										<v-card-title class="marginated titol">{{ item.name }}</v-card-title>
									</router-link>

									<v-card-text class="marginated sectores">
										<!-- <div v-if="sectors[i] !== 'undefined'">{{ sectors[i] }}</div> -->
										<div v-if="item.sectors" :id="'row_' + i" @click="showMoreSectors(item.sectorsname, i)" v>
											{{ printSectors(item.sectorsname) }}
										</div>

										<!-- <div v-else class="exhibitors-catalog__body-row-sector-text marginated sectores"></div> -->
									</v-card-text>
								</div>
								<div>
									<v-card-actions class="actions">
										<v-img v-if="item.logourl" :src="item.logourl" class="imagen" alt="logo"></v-img>
										<v-img v-else :src="defaultPhoto" class="imagen" alt="logo"></v-img>

										<v-img
											:src="require('@/assets/images/paises/' + item.countryid + '.png')"
											alt="pais"
											class="countries"
										></v-img>
										<v-spacer></v-spacer>
										<div class="border">
											<AppointmentsModal
												v-if="item.exhibitor"
												:exhibitorId="item.exhibitor"
												:enabled="item.enableappointments"
												flat
											/>
										</div>
									</v-card-actions>
								</div>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</template>
		</div>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import ShareDropdown from '@/components/common/ShareDropdown.vue';
import Modal from '@/components/common/Modal.vue';
import AppointmentsModal from '@/components/appointments/AppointmentsModal.vue';

export default {
	name: 'ExhibitorsDetailsCompanies',
	props: {
		exhibitors: [],
		exhibitor: {}
	},

	data() {
		return {
			IconContact: require('@/assets/icons/contact-calendar.svg'),
			IconFav: require('@/assets/icons/star-white.svg'),
			IconShare: require('@/assets/icons/share-white.svg'),
			IconCompanies: require('@/assets/icons/companies.svg'),
			catalog: [],
			sectors: [],
			defaultPhoto: null,
			ascending: true,
			sortBy: 'name',
			current: 1,

			pageSize: 4,
			showProgress: false
		};
	},
	components: { ShareDropdown, Modal, AppointmentsModal },
	created() {
		this.subscribeToEvents();
	},
	destroyed() {
		this.unsubscribeToEvents();
	},
	mounted() {
		this.getDefaultPhoto();
	},
	methods: {
		printSectors: function (inArray) {
			if (inArray.length > 2) {
				return inArray[0] + ', ' + inArray[1] + '...+' + (inArray.length - 2);
			} else {
				return inArray.join(', ');
			}
		},

		showMoreSectors: function (sectors, i) {
			const id = 'row_' + i;
			document.getElementById(id).innerHTML = sectors.join(', ');
		},
		getShareURL: function (exhibitorId) {
			return window.location.origin + this.$router.resolve({ name: 'ExhibitorDetails', params: { id: exhibitorId } }).href;
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		sortTable: function (colName) {
			if (this.sortBy === colName) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortBy = colName;
			}

			let ascending = this.ascending;

			this.exhibitors.sort(function (a, b) {
				if (a[colName] > b[colName]) {
					return ascending ? 1 : -1;
				} else if (a[colName] < b[colName]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},

		goPrev() {
			if (this.current > 1) this.current--;
		},
		goNext() {
			if (this.current < Math.ceil(this.exhibitors.length / this.pageSize)) this.current++;
		},
		goFirst() {
			this.current = 1;
		},
		goLast() {
			this.current = Math.ceil(this.exhibitors.length / this.pageSize);
		},
		totalPages() {
			return Array.from({ length: Math.ceil(this.exhibitors.length / this.pageSize) }, (item, index) => (item = index + 1));
		},
		subscribeToEvents() {
			this.$puiEvents.$on('searching-true', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false', () => {
				this.showProgress = false;
			});
		},
		unsubscribeToEvents() {
			this.$puiEvents.$off('searching-true');
			this.$puiEvents.$off('searching-false');
		}
	},
	computed: {
		indexStart() {
			return (this.current - 1) * this.pageSize;
		},
		indexEnd() {
			return this.indexStart + this.pageSize;
		},
		paginated() {
			return this.exhibitors.slice(this.indexStart, this.indexEnd);
		},
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	}
};
</script>

<style lang="scss" scoped>
.contact {
	width: 18px;
	height: 20px;
}
.border {
	display: flex;
	border: 1px solid #d2d7d9;
	box-sizing: border-box;
	border-radius: 8px;
}
.countries {
	margin-left: 12px;
	border-radius: 4px;
	max-height: 17.33px;
	max-width: 24px;
	min-height: 17.33px;
	min-width: 24px;
}
.bottom {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
.text {
	padding-top: 9.5rem;
}
.router {
	text-decoration: none !important;
}

.card {
	border-radius: 8px;
	margin: 0.7em !important;
	display: flex;
	flex-direction: column;
}
.row {
	margin: 20px;
}

.marginated {
	margin-left: 4px;
}
.eventImage {
	height: 151px;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	object-fit: cover;
}
.sectores {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	padding-top: 6px !important;
	/* identical to box height, or 150% */
	// min-height: 60px;
	// max-height: 60px;
	/* Neutral / Dark grey */

	color: #575e60;
}
.titol {
	font-family: Cabin;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	// min-height: 80px;
	// max-height: 80px;
	padding-bottom: 0px !important;

	/* Neutral / Extra-dark grey */

	color: #293133;
}

.imagen {
	max-width: 71px;
	max-height: 40px;
	width: auto;
	height: auto;
	object-fit: contain;
}
.actions {
	min-height: 55px;
	max-height: 55px;
	margin-left: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.exhibitors-catalog {
	background-color: #f3f5f5;
	width: 100%;
	padding: 2.5rem;
}

.exhibitors-catalog__container {
	background-color: #fff;
	border: 1px solid #e3e7e8;
	border-radius: 8px;
}

.products-catalog__body-row-name {
	&-PREMIUM {
		color: #fff;
		border-radius: 6px;
		padding: 0.125rem 0.375rem;
		font-weight: 500;
		font-size: 0.75rem;
	}
}
.exhibitors-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;

	&-info {
		margin-right: 0.5rem;
	}

	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}

	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
.exhibitor-details-products__top {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&-title {
		display: flex;
		padding-bottom: 0.812rem;

		&-text {
			padding-left: 0.75rem;
			font-family: Cabin;
			font-weight: bold;
			font-size: 26px;
			color: #293133;
		}
	}

	&-pagination {
		&-button {
			padding: 0 0.5rem;
		}
	}
}
.exhibitor-details-products__list {
	display: flex;
}

.exhibitor-details-products__item {
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0.313rem;
	width: 32%;
	height: 21rem;

	&-top {
		&-image {
			height: 9.5rem;
			width: 100%;

			&-img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px 8px 0 0;
			}

			&-PREMIUM {
				color: #fff;
				border-radius: 6px;
				padding: 0.125rem 0.375rem;

				height: 1.4rem;
				display: flex;
				align-items: center;
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 21px;
			}

			&-buttons {
				display: flex;
			}
		}

		&-text {
			padding: 10.5rem 0 1rem 1rem;
			padding-right: 1rem;

			&-title {
				font-family: Cabin;
				font-weight: bold;
				font-size: 16px;
				color: #293133;
				padding-bottom: 0.25rem;

				&-link {
					text-decoration: none;
				}
			}

			&-sector {
				font-weight: normal;
				font-size: 14px;
				color: #575e60;
			}
		}
	}

	&-bottom {
		width: 35%;
		& > img {
			width: 100%;
			max-height: 2.5rem;
			object-fit: cover;
			margin: 0 0 1rem 1rem;
		}
	}
}
</style>
