<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="500">
			<v-card>
				<v-card-title class="grey lighten-2"> {{ $t('acceptexpositordialog.title') }} </v-card-title>

				<v-card-text class="pt-4"> {{ $t('acceptexpositordialog.bodytext') }} </v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn text @click="() => handleResponse(false)"> {{ $t('acceptexpositordialog.no') }} </v-btn>

					<v-btn color="primary" text @click="() => handleResponse(true)"> {{ $t('acceptexpositordialog.yes') }} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
export default {
	props: {
		dialogTrigger: {
			Type: Number,
			Default: () => 0
		}
	},
	data() {
		return {
			dialog: false
		};
	},
	methods: {
		handleResponse(val) {
			this.dialog = false;
			this.$emit('accept', val ? 1 : 0);
		}
	},
	watch: {
		dialogTrigger() {
			this.dialog = true;
		}
	}
};
</script>
