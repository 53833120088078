<template>
	<v-dialog v-if="isLogged" v-model="dialog" width="700">
		<template v-slot:activator="{ attrs, on }">
			<v-btn v-if="flat" :disabled="!eventappointment" icon v-on="on" v-bind="attrs" style="padding: 0.75rem; margin: 0 0.25rem">
				<img :src="IconContact" alt="icon" :style="eventappointment ? '' : 'opacity: 0.25'" />
			</v-btn>
			<v-btn
				v-else
				:disabled="!eventappointment"
				class="exhibitor-details-main__right-button exhibitor-details-main__right-button--blue"
				v-on="on"
				v-bind="attrs"
			>
				<img :src="IconCalendar" alt="icon" style="padding-right: 0.5rem" />
				{{ $t('exhibitors.date') }}
			</v-btn>
		</template>
		<v-card>
			<div style="position: relative">
				<div style="position: absolute; right: 0; margin-right: 1rem; margin-top: 1rem">
					<div class="exhibitor-details-products__item-top-image-buttons">
						<img class="close" @click="dialog = false" :src="IconClose" alt="favorite" />
					</div>
				</div>
			</div>
			<v-card-title class="text-h5"> {{ $t('appointments.appointmentDate') }} </v-card-title>
			<v-form ref="form" v-model="valid" lazy-validation>
				<v-container fluid style="padding-left: 24px">
					<v-row align="center">
						<v-col class="d-flex" cols="12" sm="6">
							<pui-date-field
								v-model="date"
								:label="$t('appointments.appointmentTabDate')"
								required
								toplabel
								time
								:min="$store.state.eventInfo.evenappointmentstart"
								:max="$store.state.eventInfo.evenappointmentend"
							></pui-date-field>
						</v-col>

						<v-col class="d-flex" cols="12" sm="6">
							<Select
								v-model="select"
								:label="$t('appointments.appointmentType')"
								:rules="[(v) => !!v || 'Tipo es requerido']"
								:items="appointmentTypes"
								required
							/>
						</v-col>

						<v-col class="d-flex" cols="12" sm="12">
							<v-textarea
								class="textarea"
								v-model="comments"
								:counter="1000"
								filled
								auto-grow
								name="input-7-1"
								:rules="[(comments && comments.length < 1000) || this.$t('appointments.maxcommentrule')]"
								:label="$t('appointments.appointmentObservations')"
							></v-textarea>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn> -->

				<v-btn class="password-form__button" @click="validate" color="#2498ff" depressed>
					{{ $t('appointments.newappointment') }}
				</v-btn>

				<v-btn @click="dialog = false" class="password-form__button" color="#2498ff" depressed> {{ $t('login.cancel') }} </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<v-dialog v-else v-model="dialogNoLogued" width="378">
		<template v-slot:activator="{ attrs, on }">
			<v-btn v-if="flat" :disabled="!eventappointment" icon v-on="on" v-bind="attrs" style="padding: 0.75rem; margin: 0 0.25rem">
				<img :src="IconContact" alt="icon" :style="eventappointment ? '' : 'opacity: 0.25'" />
			</v-btn>
			<v-btn v-else class="exhibitor-details-main__right-button exhibitor-details-main__right-button--blue" v-on="on" v-bind="attrs">
				<img :src="IconCalendar" alt="icon" style="padding-right: 0.5rem" />
				{{ $t('exhibitors.date') }}
			</v-btn>
		</template>
		<v-card>
			<div style="position: relative">
				<div style="position: absolute; right: 0; margin-right: 1rem; margin-top: 1rem">
					<div class="exhibitor-details-products__item-top-image-buttons">
						<img class="close" @click="dialogNoLogued = false" :src="IconClose" alt="favorite" />
					</div>
				</div>
			</div>
			<v-card-title class="text-h5">
				{{ $t('modal.appointments') }}
			</v-card-title>
			<v-card-text>{{ $t('modal.appointmentsText') }}</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn> -->
				<router-link :to="{ name: 'Login' }" style="text-decoration: none">
					<v-btn class="password-form__button" color="#2498ff" depressed> {{ $t('menu.login') }} </v-btn>
				</router-link>
				<router-link :to="{ name: 'SignUp' }" style="text-decoration: none">
					<v-btn class="password-form__button" color="#2498ff" depressed> {{ $t('menu.register') }} </v-btn>
				</router-link>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { InsertUserAppointment } from '@/api/appointments.js';
import Select from '@/components/common/Select.vue';

export default {
	name: 'AppointmentsModal',

	props: {
		exhibitorId: {
			required: true
		},
		enabled: {
			required: true
		},
		flat: Boolean
	},

	data() {
		return {
			IconCalendar: require('@/assets/icons/calendar.svg'),
			IconContact: require('@/assets/icons/contact-calendar.svg'),
			IconClose: require('@/assets/icons/close.svg'),

			dialog: false,
			dialogNoLogued: false,
			valid: true,
			date: null,
			dateRules: [(v) => !!v || 'La fecha es requerida'],
			time: null,
			timeRules: [(v) => !!v || 'La hora es requerida'],
			select: null,
			appointmentTypes: [],
			comments: '',
			isLogged: Boolean,
			user: null,
			eventappointmentstart: null,
			evenappointmentend: null,
			evenappointmenttimestart: null,
			evenappointmenttimeend: null,
			eventappointment: Boolean,
			msg: `${this.$t('appointments.appointmentCreatedMessage')}`,
			titol: `${this.$t('appointments.appointmentCreatedTitol')}`
		};
	},

	mounted() {
		this.checkLoginData();
		this.checkEventInfo();
	},
	methods: {
		validate: async function () {
			if (this.$refs.form.validate()) {
				var body = {
					appodate: this.date,
					appoexhibitorid: this.exhibitorId,
					appostatus: 'PENDING_EXHIBITOR',
					appovisitorid: this.user.email,
					appovisitorobs: this.comments,
					appotype: this.select
				};
				let data = await InsertUserAppointment(body);
				this.$puiNotify.success(this.msg, this.titol);
				this.dialog = false;

				console.log(data);
			}
		},

		checkEventInfo() {
			if (this.$store.state.eventInfo.evenappointmentstart) {
				this.eventappointmentstart = this.$store.state.eventInfo.evenappointmentstart;
				this.evenappointmenttimestart = this.$store.state.eventInfo.evenappointmentstart;
			}
			if (this.$store.state.eventInfo.evenappointmentend) {
				this.evenappointmentend = this.$store.state.eventInfo.evenappointmentend.substring(0, 10);
				this.evenappointmenttimeend = this.$store.state.eventInfo.evenappointmentend.substring(11, 16);
			}
			if (this.$store.state.eventInfo.evenenableappointments == 1 && this.enabled == 1) {
				this.eventappointment = true;
			} else this.eventappointment = false;

			if (this.$store.state.eventInfo.evencatalogappointmentallowed) {
				const appointmentTypesArray = this.$store.state.eventInfo.evencatalogappointmentallowed.split(',');
				if (appointmentTypesArray.length === 1) this.select = appointmentTypesArray[0];
				appointmentTypesArray.every((element) => {
					if (element.toLowerCase() === 'presencial' || element.toLowerCase() === 'online') {
						this.appointmentTypes.push(element);
						return true;
					} else {
						this.appointmentTypes = ['PRESENCIAL', 'ONLINE'];
						return false;
					}
				});
			} else this.appointmentTypes = ['PRESENCIAL', 'ONLINE'];
		},
		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
			} else {
				this.isLogged = false;
			}
		}
	},

	components: { Select },
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	watch: {
		enabled: 'checkEventInfo',
		exhibitorId: 'checkEventInfo'
	}
};
</script>


<style lang="scss" >
.close {
	width: 12x !important;
	height: 12px !important;
	cursor: pointer;
}

.text-h5 {
	padding-bottom: 30px !important;
}
.textarea > .v-input__control > .v-input__slot {
	padding: 0 !important;
}

.share-dropdown {
	&__button {
		border-radius: 8px;
		padding: 0.75rem;
		max-height: 2.5rem;
		display: flex;
		align-items: center;
		margin: 0 0.25rem;
	}

	&__item {
		cursor: pointer;
	}
}
.password-form__button {
	color: #fff !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
	margin: 5px;
}
.exhibitor-details-main {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-bottom: 1.875rem;

	&__left {
		&-breadcrums {
			padding-bottom: 0.813rem;

			&-link {
				color: #575e60;
				text-decoration: none;
			}

			&-separator {
				padding: 0 0.75rem;
			}
		}

		&-container {
			&-top {
				display: flex;

				&-left {
					display: flex;
					align-items: center;
					padding-right: 1.5rem;

					&-image {
						width: 5.875rem;
						max-height: 4rem;
						object-fit: cover;
					}
				}

				&-right {
					&-title {
						font-family: Cabin;
						font-weight: bold;
						font-size: 32px;
						color: #293133;
					}

					&-subtitle {
						font-weight: normal;
						font-size: 14px;
						color: #575e60;
					}
				}
			}
		}
	}

	&__right {
		display: flex;
		align-items: center;

		&-button {
			border-radius: 8px;
			padding: 0.75rem 1.25rem;
			max-height: 2.5rem;
			display: flex;
			align-items: center;
			margin: 0 0.25rem;

			&--blue {
				background-color: #2498ff !important;
				font-family: Cabin;
				font-size: 1rem;
				color: #fff !important;
			}
			&--transparent {
				font-family: Roboto !important;
				font-style: normal !important;
				font-weight: normal !important;
				font-size: 14px !important;
				line-height: 21px !important;
				border: none !important;
				color: #575e60 !important;
			}
		}
	}
}
</style>
