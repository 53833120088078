import Pui9HttpRequests from 'pui9-requests';

export async function getProductsInterests(body) {
	const controller = `/interestvisitorproduct/list`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}


export async function getExhibitorsInterests(body) {
	const controller = `/interestvisitorexhibitor/list`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}