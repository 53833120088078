<template>
	<div v-if="products.length" class="exhibitor-details-products">
		<div class="exhibitor-details-products__top">
			<div class="exhibitor-details-products__top-title">
				<img :src="IconProducts" alt="icon" />
				<h3 class="exhibitor-details-products__top-title-text">{{ $t('exhibitors.products') }}</h3>
			</div>
			<div class="exhibitor-details-products__top-pagination">
				<button @click="goPrev()" class="exhibitor-details-products__top-pagination-button">
					<i :class="current === 1 ? 'fal fa-chevron-left' : 'fas fa-chevron-left'"></i>
				</button>
				<button @click="goNext()" class="exhibitor-details-products__top-pagination-button">
					<i :class="current === Math.ceil(products.length / pageSize) ? 'fal fa-chevron-right' : 'fas fa-chevron-right'"></i>
				</button>
			</div>
		</div>
		<div class="exhibitor-details-products__list">
			<div v-for="item in paginated" :key="item.productservice" class="exhibitor-details-products__item">
				<div class="exhibitor-details-products__item-top">
					<div style="position: relative">
						<div class="exhibitor-details-products__item-top-image" style="position: absolute">
							<router-link :to="{ name: 'ProductDetails', params: { id: item.productservice } }">
								<img class="exhibitor-details-products__item-top-image-img" v-if="item.imageurl" :src="item.imageurl" alt="photo" />
								<img class="exhibitor-details-products__item-top-image-img" v-else :src="defaultPhoto" alt="photo" />
							</router-link>
						</div>
						<div style="position: absolute; margin: 0.625rem 0 0 0.625rem">
							<span v-if="item.novetly" class="exhibitor-details-products__item-top-image-NEW">{{ $t('products.new') }}</span>
						</div>
						<div style="position: absolute; right: 0; margin-right: 0.625rem">
							<div class="exhibitor-details-products__item-top-image-buttons">
								<ShareDropdown
									:url="getShareURL(item.productservice)"
									:exhibitorName="item.exhibitorname"
									:productName="item.name"
									white
								/>
								<img :src="IconFav" alt="favorite" />
							</div>
						</div>
					</div>
					<div class="exhibitor-details-products__item-top-text">
						<router-link
							class="exhibitor-details-products__item-top-text-title-link"
							:to="{ name: 'ProductDetails', params: { id: item.productservice } }"
						>
							<h4 class="exhibitor-details-products__item-top-text-title">{{ item.name }}</h4>
						</router-link>
						<span class="exhibitor-details-products__item-top-text-sector"> {{ item.sectorname }} </span>
					</div>
				</div>
				<div class="exhibitor-details-products__item-bottom">
					<img v-if="exhibitorLogo" :src="exhibitorLogo" alt="logo" />
					<img v-else :src="defaultPhoto" alt="logo" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ShareDropdown from '@/components/common/ShareDropdown.vue';

export default {
	name: 'ExhibitorDetailsProducts',

	props: {
		exhibitorLogo: {
			type: String,
			default: null
		},
		defaultPhoto: {
			type: String,
			default: null
		},
		products: {
			type: Array
		}
	},

	components: { ShareDropdown },

	data() {
		return {
			IconProducts: require('@/assets/icons/products.svg'),
			IconShare: require('@/assets/icons/share-white.svg'),
			IconFav: require('@/assets/icons/star-white.svg'),
			pageSize: 3,
			current: 1
		};
	},

	mounted() {},

	methods: {
		goPrev() {
			if (this.current > 1) this.current--;
		},
		goNext() {
			if (this.current < Math.ceil(this.products.length / this.pageSize)) this.current++;
		},
		getShareURL: function (productId) {
			return window.location.origin + this.$router.resolve({ name: 'ProductDetails', params: { id: productId } }).href;
		}
	},

	computed: {
		indexStart() {
			return (this.current - 1) * this.pageSize;
		},
		indexEnd() {
			return this.indexStart + this.pageSize;
		},
		paginated() {
			return this.products.slice(this.indexStart, this.indexEnd);
		}
	}
};
</script>


<style lang="scss" scoped>
.exhibitor-details-products {
	padding-bottom: 2rem;
}

.exhibitor-details-products__top {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&-title {
		display: flex;
		padding-bottom: 0.812rem;

		&-text {
			padding-left: 0.75rem;
			font-family: Cabin;
			font-weight: bold;
			font-size: 26px;
			color: #293133;
		}
	}

	&-pagination {
		&-button {
			padding: 0 0.5rem;
		}
	}
}

.exhibitor-details-products__list {
	display: flex;
}

.exhibitor-details-products__item {
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0.313rem;
	width: 32%;
	height: 21rem;

	&-top {
		&-image {
			height: 9.5rem;
			width: 100%;

			&-img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px 8px 0 0;
			}

			&-NEW {
				color: #fff;
				background-color: #e36f05;
				border-radius: 6px;
				padding: 0.125rem 0.375rem;
				font-weight: 500;
				font-size: 0.75rem;
				height: 1.4rem;
				display: flex;
				align-items: center;
			}

			&-buttons {
				display: flex;
			}
		}

		&-text {
			padding: 10.5rem 0 1rem 1rem;
			padding-right: 1rem;

			&-title {
				font-family: Cabin;
				font-weight: bold;
				font-size: 16px;
				color: #293133;
				padding-bottom: 0.25rem;

				&-link {
					text-decoration: none;
				}
			}

			&-sector {
				font-weight: normal;
				font-size: 14px;
				color: #575e60;
			}
		}
	}

	&-bottom {
		width: 35%;
		& > img {
			width: 100%;
			max-height: 2.5rem;
			object-fit: cover;
			margin: 0 0 1rem 1rem;
		}
	}
}
</style>
