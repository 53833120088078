import Pui9HttpRequests from 'pui9-requests';

export async function getUserAppointments(body) {
	const controller = `/appointment/list`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}


export async function InsertUserAppointment(body) {
	const controller = `/appointment/insert`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function getAppointment(id) {
	const controller = `/appointment/get?appoid=${id}`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}


export async function acceptAppointment(id) {
	const controller = `/appointment/accept?appoid=${id}`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
				data.responseType = 'success';
			}
		},
		error => {
			if (error) {
				data = error;
				data.responseType = 'error';

			}
		}
	);

	return data;
}

export async function rejectAppointment(id) {
	const controller = `/appointment/reject?appoid=${id}`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
				data.responseType = 'success';
			}
		},
		error => {
			if (error) {
				data = error;
				data.responseType = 'error';

			}
		}
	);

	return data;
}