<template>
	<div class="exhibitor-details-main">
		<div class="exhibitor-details-main__left">
			<div class="exhibitor-details-main__left-breadcrums">
				<router-link :to="{ name: 'Home' }" class="exhibitor-details-main__left-breadcrums-link">{{ $t('exhibitors.home') }}</router-link>
				<span class="exhibitor-details-main__left-breadcrums-separator">/</span>
				<router-link :to="{ name: 'Exhibitors' }" class="exhibitor-details-main__left-breadcrums-link">{{
					$t('exhibitors.catalog')
				}}</router-link>
			</div>
			<div class="exhibitor-details-main__left-container">
				<div class="exhibitor-details-main__left-container-top">
					<div class="exhibitor-details-main__left-container-top-left">
						<img v-if="exhibitorLogo" class="exhibitor-details-main__left-container-top-left-image" :src="exhibitorLogo" alt="logo" />
						<img v-else class="exhibitor-details-main__left-container-top-left-image" :src="defaultPhoto" alt="logo" />
					</div>
					<div class="exhibitor-details-main__left-container-top-right">
						<h1 class="exhibitor-details-main__left-container-top-right-title" v-html="exhibitor.exhiname"></h1>
						<h2
							v-if="exhibitor.sectors"
							class="exhibitor-details-main__left-container-top-right-subtitle"
							:style="exhibitor.sectors.length ? '' : 'padding-bottom: 1.25rem'"
							:id="'exhibitor_sector'"
							@click="showMoreSectors(joined)"
						>
							{{ printSectors(joined) }}
						</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="exhibitor-details-main__right">
			<v-btn v-if="exhibitor.exhipavilion && exhibitor.exhistand" text disabled class="exhibitor-details-main__right-button">
				<img :src="IconMap" alt="map" style="padding-right: 0.5rem" />
				<span class="exhibitor-details-main__right-button--transparent">{{ getUbication() }}</span>
			</v-btn>
			<div class="exhibitor-details-main__right-bottom">
				<AppointmentsModal :exhibitorId="exhibitor.exhiid" :enabled="exhibitor.exhienableappointments" />
				<div class="share-dropdown">
					<Modal :exhibitor="exhibitor" favgrey />
				</div>

				<ShareDropdown outlined />
			</div>
		</div>
	</div>
</template>

<script>
import ShareDropdown from '@/components/common/ShareDropdown.vue';
import Modal from '@/components/common/Modal.vue';
import AppointmentsModal from '@/components/appointments/AppointmentsModal.vue';

export default {
	name: 'ExhibitorDetailsMain',

	props: {
		exhibitorLogo: {
			type: String,
			default: null
		},
		defaultPhoto: {
			type: String,
			default: null
		},
		exhibitor: {}
	},

	data() {
		return {
			IconCalendar: require('@/assets/icons/calendar.svg'),
			IconMap: require('@/assets/icons/map.svg'),
			IconFav: require('@/assets/icons/star-grey.svg'),
			IconClose: require('@/assets/icons/close.svg'),
			joined: [],
			reloadIcon: 0,
			isLogged: false,
			isFavourite: false,
			gotUserExhibitorInterests: true,
			favBtnProductSelected: false,
			eventappointment: Boolean
		};
	},
	mounted() {},

	destroyed() {
		this.$puiEvents.$off('reloadPage', this.checkLoginAndGetInterests);
	},
	components: { ShareDropdown, Modal, AppointmentsModal },

	methods: {
		joinSectors: function () {
			this.joined = [];
			for (let i = 0; i < this.exhibitor.sectors.length; i++) {
				this.joined.push(this.exhibitor.sectors[i].setrname);
			}
			console.log(this.joined);

			// return joined.join(' - ');
		},

		printSectors: function (inArray) {
			if (inArray.length > 2) {
				return inArray[0] + ' - ' + inArray[1] + '...+' + (inArray.length - 2);
			} else {
				return inArray.join(' - ');
			}
		},

		getUbication: function () {
			return this.exhibitor.exhipavilion + ' ' + this.exhibitor.exhistand;
		},
		showMoreSectors: function (sectors) {
			document.getElementById('exhibitor_sector').innerHTML = sectors.join(' - ');
		}
	},
	computed: {
		isFavBtnProductSelectedComp() {
			return this.favBtnProductSelected;
		},
		getCompClassFavProductBtn() {
			return {
				selected: this.favBtnProductSelected
			};
		},
		getCompUserInterests() {
			return this.interestsProducts;
		}
	},
	watch: { exhibitor: 'joinSectors' }
};
</script>


<style lang="scss" scoped>
.close {
	width: 12x !important;
	height: 12px !important;
	cursor: pointer;
}
.login-form__inputs {
	display: flex;
}
.login-form__input {
	width: 20.5rem;
	padding-left: 24px;
}
.date-field > .v-input__control > .v-input__slot {
	border: none !important;
}
.text-h5 {
	padding-bottom: 30px !important;
}
.share-dropdown {
	&__button {
		border-radius: 8px;
		padding: 0.75rem;
		max-height: 2.5rem;
		display: flex;
		align-items: center;
		margin: 0 0.25rem;
	}

	&__item {
		cursor: pointer;
	}
}
.password-form__button {
	color: #fff;
	padding-right: 15px !important;
	padding-left: 15px !important;
	margin: 5px;
}
.exhibitor-details-main {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-bottom: 1.875rem;

	@media only screen and (max-width: 960px) {
		display: contents;
	}

	&__left {
		&-breadcrums {
			padding-bottom: 0.813rem;

			&-link {
				color: #575e60;
				text-decoration: none;
			}

			&-separator {
				padding: 0 0.75rem;
			}
		}

		&-container {
			&-top {
				display: flex;

				&-left {
					display: flex;
					align-items: center;
					padding-right: 1.5rem;

					&-image {
						width: 5.875rem;
						max-height: 4rem;
						object-fit: cover;
					}
				}

				&-right {
					&-title {
						font-family: Cabin;
						font-weight: bold;
						font-size: 32px;
						color: #293133;
					}

					&-subtitle {
						font-weight: normal;
						font-size: 14px;
						color: #575e60;
					}
				}
			}
		}
	}

	&__right {
		display: flex;
		align-items: center;

		&-button {
			border-radius: 8px;
			padding: 0.75rem 1.25rem;
			max-height: 2.5rem;
			display: flex;
			align-items: center;
			margin: 0 0.25rem;

			&--blue {
				background-color: #2498ff !important;
				font-family: Cabin;
				font-size: 1rem;
				color: #fff;
			}
			&--transparent {
				font-family: Roboto !important;
				font-style: normal !important;
				font-weight: normal !important;
				font-size: 14px !important;
				line-height: 21px !important;
				border: none !important;
				color: #575e60 !important;
			}
		}
	}
}

.exhibitor-details-main__right {
	@media only screen and (max-width: 960px) {
		display: contents;
	}
}

.exhibitor-details-main__right-bottom {
	display: flex;
	@media only screen and (max-width: 960px) {
		padding-left: 1rem;
	}
}
</style>
