<template>
	<v-select
		class="select"
		:items="items"
		:item-text="itemText"
		:item-value="itemValue"
		:color="primaryColor"
		:label="label"
		:required="required"
		:rules="rules"
		v-model="selected"
		append-icon="fas fa-chevron-down"
		@change="$emit('input', selected)"
	></v-select>
</template>

<script>
export default {
	name: 'Select',
	props: {
		items: {
			type: Array,
			required: true
		},
		value: String,
		label: String,
		itemText: String,
		itemValue: String,
		required: Boolean,
		rules: Array
	},

	data() {
		return {
			selected: null
		};
	},

	created() {
		this.selected = this.value;
	},

	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	}
};
</script>


<style lang="scss">
.select {
	&.v-text-field.v-select.v-input--is-focused > .v-input__control > .v-input__slot {
		border: 0 !important;
	}

	& > .v-input__control > .v-input__slot {
		border: 0 !important;
		cursor: pointer;

		& > .v-select__slot {
			& > .v-label {
				font-size: 14px;
				margin-top: 0.5rem;

				&--active {
					margin-top: 0.125rem;
				}
			}

			& > .v-input__append-inner > .v-input__icon > .v-icon {
				font-size: 0.75rem !important;
				color: #575e60 !important;
			}
		}
	}
}
</style>
