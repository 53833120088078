<template>
	<div class="exhibitor-details">
		<div class="exhibitor-details__navbar">
			<Navbar />
		</div>
		<div class="exhibitor-details__body">
			<div class="exhibitor-details__body-top">
				<ExhibitorDetailsMain :exhibitorLogo="exhibitorLogo" :defaultPhoto="defaultPhoto" :exhibitor="exhibitor" />
			</div>
			<div class="exhibitor-details__body-middle">
				<div class="exhibitor-details__body-middle-left" style="width: 66%">
					<ExhibitorDetailsPhoto :exhibitorLogo="exhibitorLogo" :defaultPhoto="defaultPhoto" :exhibitor="exhibitor" />
					<ExhibitorDetailsDocuments :products="products" />
					<ExhibitorDetailsProducts :exhibitorLogo="exhibitorLogo" :defaultPhoto="defaultPhoto" :products="products" />
				</div>
				<div class="exhibitor-details__body-middle-right" style="width: 34%; padding-left: 2.5rem">
					<ExhibitorDetailsInfo :exhibitor="exhibitor" />
				</div>
			</div>
			<div class="exhibitor-details__body-bottom">
				<ExhibitorDetailsCompanies :exhibitors="filteredValues" :exhibitor="exhibitor" />
			</div>
		</div>
		<footer class="exhibitor-details__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import ExhibitorDetailsMain from '@/components/exhibitors/ExhibitorDetailsMain.vue';
import ExhibitorDetailsPhoto from '@/components/exhibitors/ExhibitorDetailsPhoto.vue';
import ExhibitorDetailsDocuments from '@/components/exhibitors/ExhibitorDetailsDocuments.vue';
import ExhibitorDetailsProducts from '@/components/exhibitors/ExhibitorDetailsProducts.vue';
import ExhibitorDetailsInfo from '@/components/exhibitors/ExhibitorDetailsInfo.vue';
import ExhibitorDetailsCompanies from '@/components/exhibitors/ExhibitorDetailsCompanies.vue';
import { getExhibitorBySector } from '@/api/exhibitor.js';
import { getExhibitorsInterests } from '@/api/interest.js';

import { getDocument, search, saveLogsAccess, receiveDateNow } from '@/api/common.js';
import { getExhibitorInfo } from '@/api/exhibitor.js';

export default {
	name: 'ExhibitorDetails',
	title() {
		return `${this.$t('exhibitors.title')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		ExhibitorDetailsMain,
		ExhibitorDetailsPhoto,
		ExhibitorDetailsDocuments,
		ExhibitorDetailsProducts,
		ExhibitorDetailsInfo,
		ExhibitorDetailsCompanies,
		Footer
	},
	data() {
		return {
			exhibitor: [],
			defaultPhoto: null,
			exhibitorLogo: null,
			products: [],
			filteredValues: [],
			interestExhibitors: []
		};
	},
	mounted() {
		this.getExhibitor();
		this.getExhibitorLogo();
		this.getDefaultPhoto();
		this.getProducts();
		this.checkLoginAndGetInterests();

		const user = JSON.parse(localStorage.getItem('pui9_session'));
		
		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Detalle de expositor",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacexhibitorid: this.$route.params.id,
			loacproductserviceid: 0
		});
	},

	methods: {
		getExhibitor: async function () {
			const id = this.$route.params.id;
			let data = await getExhibitorInfo(id);
			this.exhibitor = data;
			this.getIfExhibitorIsFav();
			this.getExhibitorsBySectors();
		},

		async getExhibitorsBySectors() {
			console.log(this.exhibitor);
			this.$puiEvents.$emit('searching-true', true);

			const body = this.exhibitor.sectors;

			const data = await getExhibitorBySector(body);
			this.filteredValues = data;
			this.filteredValues = this.splitSectors(this.filteredValues);
			this.shuffle(this.filteredValues);
			this.getIfExhibitorsIsFav();

			console.log(this.filteredValues);
		},

		shuffle: function (array) {
			for (let i = array.length - 1; i > 0; i--) {
				let j = Math.floor(Math.random() * (i + 1));
				[array[i], array[j]] = [array[j], array[i]];
			}
		},

		splitSectors: function (data) {
			data.map((exhibitor) => {
				if (exhibitor.sectorsname && !Array.isArray(exhibitor.sectorsname)) {
					exhibitor.sectorsname = exhibitor.sectorsname.split(',');
				}
			});
			return data;
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		getExhibitorLogo: async function () {
			const data = await getDocument('exhibitor', this.$route.params.id, 'exhibitor_logo');
			if (data[0]) this.exhibitorLogo = data[0].url;
		},

		getProducts: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						},
						{
							data: this.$route.params.id,
							field: 'exhibitor',
							op: 'eq'
						}
					]
				},
				model: 'catalogproductservice',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.products = data.data;
		},
		checkLoginAndGetInterests() {
			this.checkLoginData();
			if (this.isLogged) {
				// lo ponemos a false para que los cards no existan
				this.gotUserExhibitorInterests = false;
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
				this.getUserExhibitorsInterests();
			}
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
			} else {
				this.isLogged = false;
			}
		},

		getIfExhibitorsIsFav() {
			this.filteredValues.forEach((exhibitor) => {
				exhibitor.fav = false;
				// console.log(product);
				for (let x = 0; x < this.interestExhibitors.length; x++) {
					if (this.interestExhibitors[x].exhiid === exhibitor.exhibitor) {
						exhibitor.fav = true;
						this.favBtnProductSelected = true;
					}
				}
			});

			this.favBtnProductSelected = false;
		},
		getIfExhibitorIsFav() {
			this.exhibitor.fav = false;
			for (let x = 0; x < this.interestExhibitors.length; x++) {
				if (this.interestExhibitors[x].exhiid === this.exhibitor.exhiid) {
					this.exhibitor.fav = true;
					this.favBtnProductSelected = true;
				}
			}
		},
		getUserExhibitorsInterests: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'exhieventid',
								op: 'eq'
							},

							{
								data: this.user.email, //CORREO DEL USUARIO
								field: 'intevisitorid',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: 'interest',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: ['interest'],
					queryText: '',
					rows: 999999
				};

				let data = await getExhibitorsInterests(params);
				this.interestExhibitors = data;
				console.log(this.interestExhibitors);
			}
		}
	},
	watch: {
		$route(to, from) {
			if (to !== from) {
				location.reload();
			}
		},

		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.getExhibitor();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.exhibitor-details {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.exhibitor-details__body {
	padding: 1.75rem 8.125rem;
	@media only screen and (max-width: 960px) {
		padding: 1.75rem 2rem;
	}

	&-middle {
		display: flex;
		@media only screen and (max-width: 960px) {
			display: contents;
		}
	}
}

.exhibitor-details__body-sections {
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 10%;
}

.exhibitor-details__body-middle-left {
	@media only screen and (max-width: 960px) {
		width: 100% !important;
		padding-left: 4.352%;
		padding-right: 4.352%;
		margin: 0;
	}
}

.exhibitor-details__body-middle-right {
	@media only screen and (max-width: 960px) {
		width: 100% !important;
		padding-left: 8.352%;
		padding-right: 8.352%;
	}
}
</style>
