<template>
	<div class="exhibitor-details-info">
		<div v-if="exhibitor.brands && exhibitor.brands.length" class="exhibitor-details-info__brand">
			<p class="exhibitor-details-info__brand-titol">{{ $t('exhibitors.brands') }}</p>
			<p class="exhibitor-details-info__brand-description" v-html="brands"></p>
		</div>
		<div v-if="exhibitor.firms && exhibitor.firms.length" class="exhibitor-details-info__signatures">
			<p class="exhibitor-details-info__signatures-titol">{{ $t('exhibitors.signatures') }}</p>
			<p class="exhibitor-details-info__signatures-description">
				{{ firms }}
			</p>
		</div>
		<div v-if="exhibitor.activities && exhibitor.activities.length" class="exhibitor-details-info__activities">
			<p class="exhibitor-details-info__activities-titol">{{ $t('exhibitors.activities') }}</p>
			<p class="exhibitor-details-info__activities-description">
				{{ activities }}
			</p>
		</div>
		<div v-if="exhibitor.exhiemail !== null" class="exhibitor-details-info__contact">
			<p class="exhibitor-details-info__contact-titol">{{ $t('exhibitors.contact') }}</p>
			<div class="exhibitor-details-info__contact-container">
				<div class="exhibitor-details-info__contact-initials">{{ initials }}</div>
				<!-- <v-btn class="exhibitor-details-info__contact-fav" color="#979797" icon>
					<v-icon class="far fa-star"></v-icon>
				</v-btn> -->
				<p class="exhibitor-details-info__contact-description">
					{{ exhibitor.exhicontactperson }}
					{{ exhibitor.exhicontactposition }}
				</p>
				<p class="exhibitor-details-info__contact-link">
					<a :href="`mailto:${exhibitor.exhiemail}`">{{ exhibitor.exhiemail }}</a>
				</p>
			</div>
		</div>
		<div class="exhibitor-details-info__expositor">
			<p class="exhibitor-details-info__expositor-header">{{ $t('exhibitors.expositor_info') }}</p>
			<div v-if="exhibitor.exhiweb !== null && exhibitor.exhiweb !== ' '">
				<p class="exhibitor-details-info__expositor-titol">{{ $t('exhibitors.website') }}</p>
				<p class="exhibitor-details-info__expositor-link">
					<a @click="IncrementLinkCounter(exhibitor.exhiid)" :href="returnWebsite(exhibitor.exhiweb)" target="_blank">{{
						exhibitor.exhiweb
					}}</a>
				</p>
			</div>
		</div>
		<div v-if="exhibitor.exhiaddress !== null && exhibitor.exhiaddress !== ' '" class="exhibitor-details-info__direction">
			<p class="exhibitor-details-info__direction-titol">{{ $t('exhibitors.direction') }}</p>
			<span class="exhibitor-details-info__direction-description">{{ exhibitor.exhiaddress }}</span>
			<div>
				<span v-if="exhibitor.exhicp" class="exhibitor-details-info__direction-description" style="padding-right: 0.25rem">
					{{ exhibitor.exhicp }}
				</span>
				<span v-if="exhibitor.exhicity" class="exhibitor-details-info__direction-description">{{ exhibitor.exhicity }}</span>
			</div>
			<div>
				<span
					v-if="exhibitor.province && exhibitor.province.prtrname"
					class="exhibitor-details-info__direction-description exhibitor-details-info__direction-description--comma"
				>
					{{ exhibitor.province.prtrname }}
				</span>

				<span v-if="exhibitor.country && exhibitor.country.cotrname" class="exhibitor-details-info__direction-description">
					{{ exhibitor.country.cotrname }}
				</span>
			</div>
		</div>
		<div v-if="exhibitor.exhiphone1 !== null" class="exhibitor-details-info__telefon">
			<p class="exhibitor-details-info__telefon-titol">{{ $t('exhibitors.phone') }}</p>
			<p class="exhibitor-details-info__telefon-description">{{ exhibitor.exhiphone1 }}</p>
		</div>
		<div v-else-if="exhibitor.exhifax !== null" class="exhibitor-details-info__telefon">
			<p class="exhibitor-details-info__telefon-titol">{{ $t('exhibitors.phone') }}</p>
			<p class="exhibitor-details-info__telefon-description">{{ exhibitor.exhifax }}</p>
		</div>
		<div v-else-if="exhibitor.exhiphone2 !== null" class="exhibitor-details-info__telefon">
			<p class="exhibitor-details-info__telefon-titol">{{ $t('exhibitors.phone') }}</p>
			<p class="exhibitor-details-info__telefon-description">{{ exhibitor.exhiphone2 }}</p>
		</div>
	</div>
</template>




<script>
import { IncrementLinkCounter } from '@/api/exhibitor.js';

export default {
	name: 'ExhibitorDetailsInfo',

	props: {
		exhibitor: {}
	},

	data() {
		return {
			IconCalendar: require('@/assets/icons/calendar.svg'),
			IconMap: require('@/assets/icons/map.svg'),
			brands: '',
			activities: '',
			firms: '',
			initials: ''
		};
	},

	mounted() {},

	methods: {
		getBrands: function () {
			this.brands = [];
			this.exhibitor.brands.forEach((val, key, arr) => {
				if (Object.is(arr.length - 1, key)) {
					// execute last item logic
					this.brands += val.exbrname;
				} else this.brands += val.exbrname + ', ';
			});
			this.getActivities();
			this.getFirms();
			this.getInitials();
		},

		getActivities: function () {
			this.activities = [];
			this.exhibitor.activities.forEach((val, key, arr) => {
				if (Object.is(arr.length - 1, key)) {
					// execute last item logic
					this.activities += val.actrname;
				} else this.activities += val.actrname + ', ';
			});
		},

		getFirms: function () {
			this.firms = [];
			this.exhibitor.firms.forEach((val, key, arr) => {
				if (Object.is(arr.length - 1, key)) {
					// execute last item logic
					this.firms += val.exfiname;
				} else this.firms += val.exfiname + ', ';
			});
		},

		getInitials: function () {
			if (this.exhibitor.exhicontactperson) {
				const fullName = this.exhibitor.exhicontactperson.split(' ');
				this.initials = fullName.shift().charAt(0) + fullName.shift().charAt(0);
			}
		},

		IncrementLinkCounter: async function (id) {
			await IncrementLinkCounter(id);
		},

		returnWebsite: function (website) {
			const includeHttp = website.includes('https://') || website.includes('http://');
			if (includeHttp) {
				return website;
			}
			//if include xxx://aaa remove xxx://
			// "//" is important to relative redirect
			try {
				const url = new URL(website);
				website = url.href.replace(/(^\w+:|^)\/\//, '');
				return `//${website.slice(0, -1)}`;
			} catch (err) {
				return `//${website}`;
			}
		}
	},
	watch: {
		exhibitor: 'getBrands'
	}
};
</script>
<style lang="scss" scoped>
.exhibitor-details-info__brand,
.exhibitor-details-info__signatures,
.exhibitor-details-info__activities,
.exhibitor-details-info__direction,
.exhibitor-details-info__telefon,
.exhibitor-details-info__cif,
.exhibitor-details-info__expositor {
	// margin-bottom: 1px !important;
	margin-bottom: 6%;
	&-titol {
		font-family: Cabin;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: #575e60;
		margin-bottom: 5px !important;
	}

	&-description {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		color: #575e60;

		&--comma {
			display: inline-flex;
			padding-right: 0.25rem;

			&::after {
				content: ',';
			}
		}
	}

	&-header {
		font-family: Cabin;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		/* identical to box height */

		/* Neutral / Extra-dark grey */
		text-align: left;
		color: #293133;
		margin-bottom: 15px !important;
	}
}

.exhibitor-details-info__contact {
	margin-bottom: 11%;

	&-initials {
		float: left;
		border-radius: 50%;
		width: 35px;
		height: 35px;
		background: #575e60;
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		margin-bottom: 60px;
	}
	&-fav {
		float: right;
	}
	&-container {
		background-color: #f3f5f5;
		padding: 5%;
		border-radius: 10px;
		width: auto;
	}
	&-titol {
		font-family: Cabin;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		/* identical to box height */

		/* Neutral / Extra-dark grey */

		color: #293133;
		margin-bottom: 15px !important;
	}

	&-description {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 5px;
		/* identical to box height, or 150% */

		/* Neutral / Dark grey */

		color: #575e60;
	}
	&-link {
		position: relative;
	}
}
</style>
