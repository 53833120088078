<template>
	<v-dialog v-model="dialog" width="378">
		<template v-slot:activator="{ attrs }">
			<accept-expositor-dialog :dialogTrigger="triggerAcceptExpositorDialog" @accept="toggleFav" />
			<button
				v-if="favblue"
				@click="showAcceptExpositorDialog(product)"
				:key="reloadIcon"
				class="product-details-main__container-top-right-button product-details-main__container-top-right-button--blue"
				v-bind="attrs"
			>
				<img :key="reloadFav" :src="product.fav ? IconFavGold : IconFavWhite" alt="icon" style="padding-right: 0.5rem" />

				<span class="text">{{ $t('modal.favorites') }}</span>
			</button>
			<button
				v-else-if="favgrey"
				@click="showAcceptExpositorDialog(exhibitor)"
				:key="reloadIcon"
				class="share-dropdown__button"
				style="border: 1px solid #d2d7d9"
				v-bind="attrs"
			>
				<img :key="reloadFav" :src="exhibitor.fav ? IconFavGold : IconFavGrey" alt="icon" />
			</button>
			<button
				v-else
				@click="showAcceptExpositorDialog(products || exhibitors)"
				:class="card ? '' : 'share-dropdown__button'"
				:key="reloadIcon"
				v-bind="attrs"
			>
				<img
					v-if="products !== null && products !== undefined && card"
					:key="reloadFav"
					style="padding-top: 5px"
					:src="products.fav ? IconFavGold : IconFavWhite"
					alt="favorite"
				/>
				<img
					v-else-if="products !== null && products !== undefined"
					:key="reloadFav"
					class="exhibitors-catalog__body-row-social-item"
					:src="products.fav ? IconFavGold : IconFavGrey"
					alt="favorite"
				/>
				<img
					v-if="exhibitors !== null && exhibitors !== undefined && card"
					:key="reloadFav"
					style="padding-top: 5px"
					:src="exhibitors.fav ? IconFavGold : IconFavWhite"
					alt="favorite"
				/>
				<img
					v-else-if="exhibitors !== null && exhibitors !== undefined"
					:key="reloadFav"
					class="exhibitors-catalog__body-row-social-item"
					:src="exhibitors.fav ? IconFavGold : IconFavGrey"
					alt="favorite"
				/>
				<!--   -->
			</button>
		</template>
		<v-card>
			<div style="position: relative">
				<div style="position: absolute; right: 0; margin-right: 1rem; margin-top: 1rem">
					<div class="exhibitor-details-products__item-top-image-buttons">
						<img class="close" @click="dialog = false" :src="IconClose" alt="favorite" />
					</div>
				</div>
			</div>
			<v-card-title class="text-h5">
				{{ $t('modal.favorites') }}
			</v-card-title>
			<v-card-text>{{ $t('modal.text') }}</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn> -->
				<router-link :to="{ name: 'Login' }" style="text-decoration: none">
					<v-btn class="password-form__button" color="#2498ff" depressed> {{ $t('menu.login') }} </v-btn>
				</router-link>
				<router-link :to="{ name: 'SignUp' }" style="text-decoration: none">
					<v-btn class="password-form__button" color="#2498ff" depressed> {{ $t('menu.register') }} </v-btn>
				</router-link>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>



<script>
import Pui9HttpRequests from 'pui9-requests';
import AcceptExpositorDialog from './AcceptExpositorDialog.vue';

export default {
	name: 'Modal',

	props: {
		product: {},
		exhibitor: {},
		products: null,
		exhibitors: null,
		favblue: Boolean,
		favgrey: Boolean,
		card: Boolean,
		interestFav: Boolean
	},
	components: { AcceptExpositorDialog },

	data() {
		return {
			triggerAcceptExpositorDialog: 0,
			IconFavWhite: require('@/assets/icons/star-white.svg'),
			IconFavGrey: require('@/assets/icons/star-grey.svg'),
			IconFavGold: require('@/assets/icons/star-gold.svg'),

			IconClose: require('@/assets/icons/close.svg'),
			addProductInterestController: '/interestvisitorproduct/addInterest',
			removeProductInterestController: '/interestvisitorproduct/removeInterest',
			addExhibitorInterestController: '/interestvisitorexhibitor/addInterest',
			removeExhibitorInterestController: '/interestvisitorexhibitor/removeInterest',
			reloadIcon: 0,
			isLogged: false,
			isFavourite: false,
			gotUserExhibitorInterests: true,
			gotUserProductInterests: true,
			InterestsV: [],
			reloadFav: 0,
			favBtnProductSelected: false,
			dialog: false
		};
	},
	mounted() {
		this.checkLoginData();
	},
	methods: {
		showAcceptExpositorDialog(item = {}) {
			if (!item.fav) {
				this.triggerAcceptExpositorDialog = this.triggerAcceptExpositorDialog + 1;
			} else {
				this.toggleFav(0);
			}
		},
		toggleFav: async function (acceptInterest) {
			if (this.isLogged) {
				var controller = '';
				if (this.product) {
					if (this.product.fav) {
						this.product.fav = false;
						this.favBtnProductSelected = this.product.fav;
						controller = this.removeProductInterestController + '?prsrid=' + this.product.prsrid;
						await Pui9HttpRequests.getRequest(
							controller,
							null,
							() => {
								this.reloadFav++;
							},
							(error) => {
								if (error) {
									this.$puiNotify.error();
								}
							},
							{
								workingRole: 'VISITOR'
							}
						);
					} else {
						this.product.fav = true;
						this.favBtnProductSelected = this.product.fav;
						controller = this.addProductInterestController + '?origin=CATALOG&prsrid=' + this.product.prsrid;
						this.$puiRequests.getRequest(
							controller,
							{ acceptInterest },
							() => {
								this.reloadFav++;
							},
							(error) => {
								if (error) {
									this.$puiNotify.error();
								}
							},
							{
								workingRole: 'VISITOR'
							}
						);
					}
				} else if (this.products) {
					if (this.interestFav) {
						if (this.products.fav) {
							this.products.fav = false;
							this.favBtnProductSelected = this.products.fav;
							controller = this.removeProductInterestController + '?prsrid=' + this.products.inteproductserviceid;
							await Pui9HttpRequests.getRequest(
								controller,
								null,
								() => {
									this.$puiEvents.$emit('reloadFav');
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						} else {
							this.products.fav = true;
							this.favBtnProductSelected = this.products.fav;
							controller = this.addProductInterestController + '?origin=CATALOG&prsrid=' + this.products.inteproductserviceid;
							this.$puiRequests.getRequest(
								controller,
								{ acceptInterest },
								() => {
									this.reloadFav++;
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						}
					} else {
						if (this.products.fav) {
							this.products.fav = false;
							this.favBtnProductSelected = this.products.fav;
							controller = this.removeProductInterestController + '?prsrid=' + this.products.productservice;
							await Pui9HttpRequests.getRequest(
								controller,
								null,
								() => {
									this.reloadFav++;
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						} else {
							this.products.fav = true;
							this.favBtnProductSelected = this.products.fav;
							controller = this.addProductInterestController + '?origin=CATALOG&prsrid=' + this.products.productservice;
							this.$puiRequests.getRequest(
								controller,
								{ acceptInterest },
								() => {
									this.reloadFav++;
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						}
					}
				} else if (this.exhibitor) {
					if (this.exhibitor.fav) {
						this.exhibitor.fav = false;
						this.favBtnProductSelected = this.exhibitor.fav;
						controller = this.removeExhibitorInterestController + '?exhiid=' + this.exhibitor.exhiid;
						await Pui9HttpRequests.getRequest(
							controller,
							null,
							() => {
								this.reloadFav++;
							},
							(error) => {
								if (error) {
									this.$puiNotify.error();
								}
							},
							{
								workingRole: 'VISITOR'
							}
						);
					} else {
						this.exhibitor.fav = true;
						this.favBtnProductSelected = this.exhibitor.fav;
						controller = this.addExhibitorInterestController + '?origin=CATALOG&exhiid=' + this.exhibitor.exhiid;
						this.$puiRequests.getRequest(
							controller,
							{ acceptInterest },
							() => {
								this.reloadFav++;
							},
							(error) => {
								if (error) {
									this.$puiNotify.error();
								}
							},
							{
								workingRole: 'VISITOR'
							}
						);
					}
				} else if (this.exhibitors) {
					if (this.interestFav) {
						if (this.exhibitors.fav) {
							this.exhibitors.fav = false;
							this.favBtnProductSelected = this.exhibitors.fav;
							controller = this.removeExhibitorInterestController + '?exhiid=' + this.exhibitors.exhiid;
							await Pui9HttpRequests.getRequest(
								controller,
								null,
								() => {
									this.$puiEvents.$emit('reloadFav');
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						} else {
							this.exhibitors.fav = true;
							this.favBtnProductSelected = this.exhibitors.fav;
							controller = this.addExhibitorInterestController + '?origin=CATALOG&exhiid=' + this.exhibitors.exhiid;
							this.$puiRequests.getRequest(
								controller,
								{ acceptInterest },
								() => {
									this.reloadFav++;
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						}
					} else {
						if (this.exhibitors.fav) {
							this.exhibitors.fav = false;
							this.favBtnProductSelected = this.exhibitors.fav;
							controller = this.removeExhibitorInterestController + '?exhiid=' + this.exhibitors.exhibitor;
							await Pui9HttpRequests.getRequest(
								controller,
								null,
								() => {
									this.reloadFav++;
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						} else {
							this.exhibitors.fav = true;
							this.favBtnProductSelected = this.exhibitors.fav;
							controller = this.addExhibitorInterestController + '?origin=CATALOG&exhiid=' + this.exhibitors.exhibitor;
							this.$puiRequests.getRequest(
								controller,
								{ acceptInterest },
								() => {
									this.reloadFav++;
								},
								(error) => {
									if (error) {
										this.$puiNotify.error();
									}
								},
								{
									workingRole: 'VISITOR'
								}
							);
						}
					}
				}
			} else {
				this.dialog = true;
			}
		},
		checkLoginAndGetInterests() {
			this.checkLoginData();
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
			} else {
				this.isLogged = false;
			}
		}
	},
	computed: {
		isFavBtnProductSelectedComp() {
			return this.favBtnProductSelected;
		},

		getCompUserInterests() {
			return this.InterestsV;
		},

		getCompClassFavProductBtn() {
			return {
				selected: this.favBtnProductSelected
			};
		}
	}

	// watch: {
	// 	product: 'checkLoginAndGetInterests',
	// 	exhibitor: 'checkLoginAndGetInterests'
	// }
};
</script>

<style lang="scss" scoped>
.product-details-main__container {
	&-top {
		display: flex;
		justify-content: space-between;

		&-left {
			display: flex;
			align-items: center;

			&-logo {
				display: flex;
				align-items: center;
				padding-right: 1.5rem;

				&-image {
					width: 5.875rem;
					max-height: 4rem;
					object-fit: cover;
				}
			}

			&-text {
				&-title {
					font-family: Cabin;
					font-weight: bold;
					font-size: 32px;
					color: #293133;
				}

				&-subtitle {
					font-weight: normal;
					font-size: 14px;
					color: #575e60;
				}
			}
		}

		&-right {
			display: flex;
			align-items: center;

			&-button {
				border-radius: 8px;
				padding: 0.75rem 1.25rem;
				max-height: 2.5rem;
				display: flex;
				align-items: center;
				margin: 0 0.25rem;

				&--blue {
					background-color: #2498ff !important;
					font-family: Cabin;
					font-size: 1rem;
					color: #fff;
				}

				&--white {
					border: 1px solid #d2d7d9;
					font-family: Cabin;
					font-weight: bold;
					font-size: 1rem;
					color: #575e60;
				}
			}
		}
	}

	&-middle {
		padding: 1.875rem 0;
		display: flex;
		justify-content: center;

		&-image {
			border-radius: 8px;
			width: 100%;
			max-height: 31.25rem;
			object-fit: cover;
		}
	}
}

.share-dropdown__button {
	border-radius: 8px;
	padding: 0.75rem;
	max-height: 2.5rem;
	display: flex;
	align-items: center;
	margin: 0 0.25rem;
}

.text {
	font-family: Cabin;
	font-style: normal;
	font-size: 16px;
	line-height: 19px;
}

.close {
	width: 12x !important;
	height: 12px !important;
	cursor: pointer;
}

.password-form__button {
	color: #fff;
	padding-right: 15px !important;
	padding-left: 15px !important;
	margin: 5px;
}
</style>
